//2024
import img202401 from "../../images/culture/Culture2024/1.jpg"
import img202402 from "../../images/culture/Culture2024/2.jpg"
import img202403 from "../../images/culture/Culture2024/3.jpg"
import img202404 from "../../images/culture/Culture2024/4.jpg"
import img202405 from "../../images/culture/Culture2024/5.jpg"
import img202406 from "../../images/culture/Culture2024/6.jpg"
import img202407 from "../../images/culture/Culture2024/7.jpg"
import img202408 from "../../images/culture/Culture2024/8.jpg"
import img202409 from "../../images/culture/Culture2024/9.jpg"
import img202410 from "../../images/culture/Culture2024/10.jpg"
import img202411 from "../../images/culture/Culture2024/11.jpg"
import img202412 from "../../images/culture/Culture2024/12.jpg"
import img202413 from "../../images/culture/Culture2024/13.jpg"
import img202414 from "../../images/culture/Culture2024/14.jpg"
import img202415 from "../../images/culture/Culture2024/15.jpg"
import img202416 from "../../images/culture/Culture2024/16.jpg"

// 2023
import img20231 from "../../images/culture/Culture2023/1.jpg"
import img20232 from "../../images/culture/Culture2023/2.jpg"
import img20233 from "../../images/culture/Culture2023/3.jpg"
import img20234 from "../../images/culture/Culture2023/4.jpg"
import img20235 from "../../images/culture/Culture2023/5.jpg"
import img20236 from "../../images/culture/Culture2023/6.jpg"
import img20237 from "../../images/culture/Culture2023/7.jpg"
import img20238 from "../../images/culture/Culture2023/8.jpg"
import img20239 from "../../images/culture/Culture2023/9.jpg"
import img202310 from "../../images/culture/Culture2023/10.jpg"

// 2019
import img20191 from "../../images/culture/Culture2019/1.jpg"
import img20192 from "../../images/culture/Culture2019/2.jpg"
import img20193 from "../../images/culture/Culture2019/3.jpg"
import img20194 from "../../images/culture/Culture2019/4.jpg"
import img20195 from "../../images/culture/Culture2019/5.jpg"
import img20196 from "../../images/culture/Culture2019/6.jpg"
import img20197 from "../../images/culture/Culture2019/7.jpg"
import img20198 from "../../images/culture/Culture2019/8.jpg"
import img20199 from "../../images/culture/Culture2019/9.jpg"
import img201910 from "../../images/culture/Culture2019/10.jpg"
import img201911 from "../../images/culture/Culture2019/11.jpg"
import img201912 from "../../images/culture/Culture2019/12.jpg"
import img201913 from "../../images/culture/Culture2019/13.jpg"
import img201914 from "../../images/culture/Culture2019/14.jpg"
import img201915 from "../../images/culture/Culture2019/15.jpg"
import img201916 from "../../images/culture/Culture2019/16.jpg"
import img201917 from "../../images/culture/Culture2019/17.jpg"
import img201918 from "../../images/culture/Culture2019/18.jpg"
import img201919 from "../../images/culture/Culture2019/19.jpg"
import img201920 from "../../images/culture/Culture2019/20.jpg"

// 2018
import img20181 from "../../images/culture/Culture2018/1.jpg"
import img20182 from "../../images/culture/Culture2018/2.jpg"
import img20183 from "../../images/culture/Culture2018/3.jpg"
import img20184 from "../../images/culture/Culture2018/4.jpg"
import img20185 from "../../images/culture/Culture2018/5.jpg"
import img20186 from "../../images/culture/Culture2018/6.jpg"
import img20187 from "../../images/culture/Culture2018/7.jpg"
import img20188 from "../../images/culture/Culture2018/8.jpg"
import img20189 from "../../images/culture/Culture2018/9.jpg"
import img201810 from "../../images/culture/Culture2018/10.jpg"
import img201811 from "../../images/culture/Culture2018/11.jpg"
import img201812 from "../../images/culture/Culture2018/12.jpg"
import img201813 from "../../images/culture/Culture2018/13.jpg"
import img201814 from "../../images/culture/Culture2018/14.jpg"
import img201815 from "../../images/culture/Culture2018/15.jpg"

// 2017
import img20171 from "../../images/culture/Culture2017/1.jpg"
import img20172 from "../../images/culture/Culture2017/2.jpg"
import img20173 from "../../images/culture/Culture2017/3.jpg"
import img20174 from "../../images/culture/Culture2017/4.jpg"
import img20175 from "../../images/culture/Culture2017/5.jpg"
import img20176 from "../../images/culture/Culture2017/6.jpg"
import img20177 from "../../images/culture/Culture2017/7.jpg"
import img20178 from "../../images/culture/Culture2017/8.jpg"
import img20179 from "../../images/culture/Culture2017/9.jpg"
import img201710 from "../../images/culture/Culture2017/10.jpg"
import img201711 from "../../images/culture/Culture2017/11.jpg"
import img201712 from "../../images/culture/Culture2017/12.jpg"

const CULTURE_DATA = [
  // 2017
  {
    year: "2017",
    img: img20171,
  },
  {
    year: "2017",
    img: img20172,
  },
  {
    year: "2017",
    img: img20173,
  },
  {
    year: "2017",
    img: img20174,
  },
  {
    year: "2017",
    img: img20175,
  },
  {
    year: "2017",
    img: img20176,
  },
  {
    year: "2017",
    img: img20177,
  },
  {
    year: "2017",
    img: img20178,
  },
  {
    year: "2017",
    img: img20179,
  },
  {
    year: "2017",
    img: img201710,
  },
  {
    year: "2017",
    img: img201711,
  },
  {
    year: "2017",
    img: img201712,
  },
  // 2018
  {
    year: "2018",
    img: img20181,
  },
  {
    year: "2018",
    img: img20182,
  },
  {
    year: "2018",
    img: img20183,
  },
  {
    year: "2018",
    img: img20184,
  },
  {
    year: "2018",
    img: img20185,
  },
  {
    year: "2018",
    img: img20186,
  },
  {
    year: "2018",
    img: img20187,
  },
  {
    year: "2018",
    img: img20188,
  },
  {
    year: "2018",
    img: img20189,
  },
  {
    year: "2018",
    img: img201810,
  },
  {
    year: "2018",
    img: img201811,
  },
  {
    year: "2018",
    img: img201812,
  },
  {
    year: "2018",
    img: img201813,
  },
  {
    year: "2018",
    img: img201814,
  },
  {
    year: "2018",
    img: img201815,
  },
  // 2019
  {
    year: "2019",
    img: img20191,
  },
  {
    year: "2019",
    img: img20192,
  },
  {
    year: "2019",
    img: img20193,
  },
  {
    year: "2019",
    img: img20194,
  },
  {
    year: "2019",
    img: img20195,
  },
  {
    year: "2019",
    img: img20196,
  },
  {
    year: "2019",
    img: img20197,
  },
  {
    year: "2019",
    img: img20198,
  },
  {
    year: "2019",
    img: img20199,
  },
  {
    year: "2019",
    img: img201910,
  },
  {
    year: "2019",
    img: img201911,
  },
  {
    year: "2019",
    img: img201912,
  },
  {
    year: "2019",
    img: img201913,
  },
  {
    year: "2019",
    img: img201914,
  },
  {
    year: "2019",
    img: img201915,
  },
  {
    year: "2019",
    img: img201916,
  },
  {
    year: "2019",
    img: img201917,
  },
  {
    year: "2019",
    img: img201918,
  },
  {
    year: "2019",
    img: img201919,
  },
  {
    year: "2019",
    img: img201920,
  },
  //2023
  {
    year: "2023",
    img: img20231,
  },
  {
    year: "2023",
    img: img20232,
  },
  {
    year: "2023",
    img: img20233,
  },
  {
    year: "2023",
    img: img20234,
  },
  {
    year: "2023",
    img: img20235,
  },
  {
    year: "2023",
    img: img20236,
  },
  {
    year: "2023",
    img: img20237,
  },
  {
    year: "2023",
    img: img20238,
  },
  {
    year: "2023",
    img: img20239,
  },
  {
    year: "2023",
    img: img202310,
  },
  {
    year: "2024",
    img: img202401,
  },
  {
    year: "2024",
    img: img202402,
  },
  {
    year: "2024",
    img: img202403,
  },
  {
    year: "2024",
    img: img202404,
  },
  {
    year: "2024",
    img: img202405,
  },
  {
    year: "2024",
    img: img202406,
  },
  {
    year: "2024",
    img: img202407,
  },
  {
    year: "2024",
    img: img202408,
  },
  {
    year: "2024",
    img: img202409,
  },
  {
    year: "2024",
    img: img202410,
  },
  {
    year: "2024",
    img: img202411,
  },
  {
    year: "2024",
    img: img202412,
  },
  {
    year: "2024",
    img: img202413,
  },
  {
    year: "2024",
    img: img202414,
  },
  {
    year: "2024",
    img: img202415,
  },
  {
    year: "2024",
    img: img202416,
  },
]

export default CULTURE_DATA
