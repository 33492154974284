import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { Swiper } from "swiper/dist/js/swiper.esm"
import CULTURE_DATA from "./data"
import media from "../../styles/media"

const Container = styled.div`
  padding: var(--gutter-s);
  .swiper-container {
    height: 400px;
  }
  .swipwe-wrapper {
    max-width: 100%;
  }
  .swiper-slide {
    width: auto;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .flex-nav {
    display: flex;
    margin: 0;
    align-items: center;
  }
  ${media.tablet`
    padding: var(--gutter-l) 0 var(--gutter-l) var(--gutter-l);
    .swiper-slide {
      max-height: 500px;
      width: auto;
      margin-right: 30px;
    }
  `}
`

const Year = styled.h1`
  font-weight: 600;
  font-family: "circular-std-black";
  text-align: center;
  font-size: 3rem;
  margin: 0;
  margin-bottom: -1.5rem;
  ${media.tablet`
    position: relative;
    text-align: left;
    transform: translateY(35%);
    font-size: 6.5rem;
    z-index: 2;
  `}
`

const Arrows = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  /* margin-right: 2rem; */
  div {
    opacity: 0.25;
    transition: all 250ms ease-out;
    padding: 1rem;
    &.active {
      opacity: 1;
      padding: 1rem;
    }
    &:first-child {
      margin-right: 10px;
    }
  }
  ${media.tablet`
    width: calc(31% + 10rem);
    justify-content: flex-end;
  `}
`

const ActiveSlide = styled.div`
  margin-left: 2rem;
  ${media.tablet`
    margin: 0;
  `}
`

const PastYearSelector = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  justify-items: flex-end;
  position: relative;
  padding: 1rem 0;
  z-index: 2;
  margin-bottom: 1em;
  margin-top: 2em;
  margin-left: -2em;
  .flex {
    display: flex;
    p,
    button {
      margin-left: 5px;
      font-size: 0.9em;
      font-family: "circular-std-medium";
    }
    p {
      padding-top: 1em;
    }
    button {
      border: none;
      background-color: transparent;
      display: inline-block;
      position: relative;
      transition: all 250ms ease-out;
      padding: 0.1rem;
      margin: 0 0.5rem;
      &:focus {
        outline: none;
      }
      &:after {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 1);
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
      }
      &.active {
        color: var(--carnation);
        &:after {
          display: none;
        }
      }
      &:hover {
        color: var(--carnation);
      }
    }
    .label {
      margin-right: 5px;
    }
  }
  ${media.tablet`
    padding: 0;
    padding-right: 8rem;
    margin-top: 0;
    button {
      margin-top: -0.2em;
    }
    .flex {
      p {
        padding-top: 0;
      }
    }
  `}
`

const Slide = styled.div`
  position: relative;
  .img-container {
    width: auto;
    height: 100%;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: transform 2500ms ease-out !important;
      transform: ${props => (props.zoom ? `scale(1.1)` : `scale(1)`)};
    }
    &:hover {
      img {
        transition: transform 2500ms ease-out !important;
        transform: scale(1.1);
      }
    }
  }
`

const Culture = () => {
  const [year, setYear] = useState("2024")

  const [zoom, setZoom] = useState(false)

  const renderCultureData = () => {
    return CULTURE_DATA.filter(culture => {
      return culture.year === year
    }).map((culture, index) => {
      return (
        <Slide key={index} className="swiper-slide" year={year} zoom={zoom}>
          <div className="img-container">
            <img src={culture.img} />
          </div>
        </Slide>
      )
    })
  }

  const swiperElement = useRef(null)
  const swiper = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      speed: 1000,
      freeMode: true,
      freeModeSticky: true,
      breakpointsInverse: true,
      touchStartPreventDefault: false,
      rebuildOnUpdate: true,
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      breakpoints: {
        768: {
          slidesPerView: "auto",
          centeredSlides: false,
        },
      },
      on: {
        transitionEnd: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex)
          }
        },
        touchStart: () => {
          setZoom(true)
        },
        touchEnd: () => {
          setZoom(false)
        },
      },
    })
  }, [])

  const slideNext = () => {
    if (swiper.current) {
      swiper.current.slideNext()
    }
  }
  const slidePrev = () => {
    if (swiper.current) {
      swiper.current.slidePrev()
    }
  }
  const slideTo = index => {
    swiper.current.slideTo(index, 800)
  }

  const YearClicked = e => {
    slideTo(0)
    setYear(e.target.value)
  }

  return (
    <Container>
      <Year>{year}</Year>
      <PastYearSelector>
        <div className="flex">
          <p className="label">past years </p>
          <button
            onClick={YearClicked}
            value="2024"
            className={year === "2024" ? "active mouse-link" : " mouse-link"}
          >
            2024
          </button>
          <p>/</p>
          <button
            onClick={YearClicked}
            value="2023"
            className={year === "2023" ? "active mouse-link" : " mouse-link"}
          >
            2023
          </button>
          <p>/</p>
          <button
            onClick={YearClicked}
            value="2019"
            className={year === "2019" ? "active mouse-link" : " mouse-link"}
          >
            2019
          </button>
          <p>/</p>
          <button
            onClick={YearClicked}
            value="2018"
            className={year === "2018" ? "active mouse-link" : " mouse-link"}
          >
            2018
          </button>
          <p>/</p>
          <button
            onClick={YearClicked}
            value="2017"
            className={year === "2017" ? "active mouse-link" : " mouse-link"}
          >
            2017
          </button>
        </div>
      </PastYearSelector>
      <div ref={swiperElement} className="swiper-container slider-swipe">
        <div className="swiper-wrapper">{renderCultureData()}</div>
      </div>
      <div className="flex-nav">
        <ActiveSlide>
          <span>
            {activeSlide < 9 ? "0" : null}
            {activeSlide + 1}
          </span>
          <span className="divider">&#8213;</span>
          <span>{renderCultureData().length}</span>
        </ActiveSlide>
        <Arrows>
          <div
            className={activeSlide > 0 ? `active mouse-link` : `mouse-link`}
            onClick={slidePrev}
          >
            &#8592;
          </div>
          <div
            className={
              activeSlide < CULTURE_DATA.length - 1
                ? `active mouse-link`
                : `mouse-link`
            }
            onClick={slideNext}
          >
            &#8594;
          </div>
        </Arrows>
      </div>
    </Container>
  )
}

export default Culture
