import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Culture from "../components/Culture"

const CulturePage = () => {
  return (
    <Layout>
      <SEO title="Culture" />
      <Culture />
    </Layout>
  )
}

export default CulturePage
